var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-addresses generic-list scroll-container"},[_c('CategoryTitle',{attrs:{"category":_vm.category}}),_c('v-list',_vm._l((_vm.userAddresses.addresses),function(address){return _c('v-list-item',{key:address.addressId},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('strong',[_vm._v(_vm._s(address.addressName))]),_vm._v(" "+_vm._s(_vm.facilitatedAddress && _vm.facilitatedAddress.addressId == address.addressId ? " (Consegna Agevolata)" : "")+" ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(" "+_vm._s(address.address1)+" "+_vm._s(address.addressNumber)+" - "+_vm._s(address.city)+" ("+_vm._s(address.province)+")")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[(
              address.addressType == 'home' &&
                _vm.facilitatedAddress &&
                _vm.facilitatedAddress.addressId == address.addressId
            )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"large":"","icon":"","disabled":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("$edit")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("L’indirizzo non è modificabile in quanto è associato alle consegne agevolate.")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
                  (address.addressType == 'home' && !_vm.facilitatedAddress) ||
                    (address.addressType == 'home' &&
                      _vm.facilitatedAddress &&
                      _vm.facilitatedAddress.addressId != address.addressId)
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-2",attrs:{"icon":"","large":"","to":'/profile/addresses/manage/' + address.addressId}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica indirizzo")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(
                  (address.addressType == 'home' && !_vm.facilitatedAddress) ||
                    (address.addressType == 'home' &&
                      _vm.facilitatedAddress &&
                      _vm.facilitatedAddress.addressId != address.addressId)
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"secondary-icon-button",attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.removeAddress(address.addressId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Elimina indirizzo")])]),(
              address.addressType == 'home' &&
                _vm.facilitatedAddress &&
                _vm.facilitatedAddress.addressId == address.addressId
            )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","disabled":""},on:{"click":function($event){return _vm.removeAddress(address.addressId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("L’indirizzo non può essere eliminato in quanto è associato alle consegne agevolate.")])]):_vm._e()],1)],1)],1)}),1),_c('v-row',{staticClass:"d-flex justify-space-between footer-btn pl-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary","large":"","depressed":"","to":"/profile/addresses/manage"}},[_vm._v(" "+_vm._s(_vm.$t("addresses.addAddressButton"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }