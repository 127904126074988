<template>
  <div class="profile-addresses generic-list scroll-container">
    <!-- <v-container> -->
    <CategoryTitle :category="category" />

    <v-list>
      <v-list-item
        v-for="address in userAddresses.addresses"
        :key="address.addressId"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row no-gutters align="center">
          <v-col cols="3" sm="3">
            <strong>{{ address.addressName }}</strong>
            {{
              facilitatedAddress &&
              facilitatedAddress.addressId == address.addressId
                ? " (Consegna Agevolata)"
                : ""
            }}
          </v-col>
          <v-col cols="6">
            <span>
              {{ address.address1 }} {{ address.addressNumber }} -
              {{ address.city }} ({{ address.province }})</span
            >
          </v-col>
          <v-col cols="3" class="d-flex justify-end">
            <v-tooltip
              left
              v-if="
                address.addressType == 'home' &&
                  facilitatedAddress &&
                  facilitatedAddress.addressId == address.addressId
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    large
                    icon
                    class="mr-2"
                    disabled
                  >
                    <v-icon medium>$edit</v-icon>
                  </v-btn>
                </span>
              </template>
              <span
                >L’indirizzo non è modificabile in quanto è associato alle
                consegne agevolate.</span
              >
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  large
                  class="main-button mr-2"
                  :to="'/profile/addresses/manage/' + address.addressId"
                  v-if="
                    (address.addressType == 'home' && !facilitatedAddress) ||
                      (address.addressType == 'home' &&
                        facilitatedAddress &&
                        facilitatedAddress.addressId != address.addressId)
                  "
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica indirizzo</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="secondary-icon-button"
                  @click="removeAddress(address.addressId)"
                  v-if="
                    (address.addressType == 'home' && !facilitatedAddress) ||
                      (address.addressType == 'home' &&
                        facilitatedAddress &&
                        facilitatedAddress.addressId != address.addressId)
                  "
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina indirizzo</span>
            </v-tooltip>
            <v-tooltip
              left
              v-if="
                address.addressType == 'home' &&
                  facilitatedAddress &&
                  facilitatedAddress.addressId == address.addressId
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on">
                  <v-btn
                    large
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="removeAddress(address.addressId)"
                    disabled
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </span>
              </template>
              <span
                >L’indirizzo non può essere eliminato in quanto è associato alle
                consegne agevolate.</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>

    <v-row no-gutters class="d-flex justify-space-between footer-btn pl-0">
      <v-btn color="primary" large depressed to="/profile/addresses/manage">
        {{ $t("addresses.addAddressButton") }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import DeliveryService from "~/service/deliveryService";
// import Address from "@/components/delivery/Address";
import CategoryTitle from "@/components/category/CategoryTitle";

import categoryMixins from "~/mixins/category";

export default {
  name: "ProfileAddress",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      newAddress: {
        addressId: -1,
        addressName: "",
        address1: "",
        address2: "",
        addressNumber: "",
        postalcode: "",
        city: "",
        province: ""
      },
      userAddresses: []
    };
  },

  methods: {
    async fetchAddresses() {
      this.userAddresses = await DeliveryService.getWarehousesList("home");
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    },
    selectAddress(address) {
      DeliveryService.selectAddress(address.addressId).then(function() {
        //go to home page
      });
    },
    addAddress() {
      // this.$router.push({
      //   path: "/edit-addresses/",
      //   name: "/edit-addresses/",
      //   params: {
      //     address: this.newAddress,
      //     newAddress: true
      //   }
      // });
    }
  },
  computed: {
    facilitatedAddressEnabled() {
      let facilitatedLegalFound = false;
      for (let i = 0; i < this.user.legals.length; i++) {
        if ([4].includes(this.user.legals[i].legalId)) {
          facilitatedLegalFound = true;
          break;
        }
      }
      return facilitatedLegalFound;
    },
    facilitatedAddress() {
      var _this = this;
      console.log(_this);
      if (!this.userAddresses) {
        this.fetchAddresses();
      }
      let found = this.userAddresses.addresses
        .map(function(address) {
          // Does current address has any type '999' class?
          let addressClasses = address.addressClass ? address.addressClass : [];
          let facilitatedEnabled = addressClasses.reduce(function(
            total,
            currentValue
          ) {
            return total || currentValue["address-class-id"] === 999;
          },
          false);
          return {
            addressId: address.addressId,
            addressName: address.addressName,
            facilitatedEnabled: facilitatedEnabled
          };
        })
        .filter(function(address) {
          return address.facilitatedEnabled;
        })
        .shift();

      return found ? found : null;
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
